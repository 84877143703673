@tailwind base;
@tailwind components;
@tailwind utilities;


body{
  font-family: 'Poppins', 'sans-serif';
  font-size: 16px;
  font-weight: 400;
}

.form-control{
  margin-bottom: 10px;
}

.form-control input, select, textarea {
  display: block;
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #dbdfe9;
  box-shadow: false;
  background-color: white;
  outline: none;
  margin: 3px 0;
}

.form-control input:disabled, select:disabled, textarea:disabled {
  @apply bg-slate-100
}

.form-control label{
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  margin-bottom: 10px;
}

.form-control input::placeholder select::placeholder, textarea::placeholder{
    color: #9c9c9c;
}

.sidebar .active{
  @apply bg-blue-50 text-primary
}

.sidebar .active svg{
  @apply stroke-primary fill-blue-200
}

.input-group {
  @apply relative
}

.input-group input{
  @apply pe-[40px]
}

.input-group .icon{
  @apply absolute top-[10px] right-[10px] cursor-pointer
}

.login-bg{
  background-image: url('./assets/images/login-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

::-webkit-scrollbar-thumb {
  background: #a8a8a8; 
}

::-webkit-scrollbar-thumb:hover {
  background: #9f9f9f; 
}


.jodit-status-bar-link{
  display:  none !important;
}

.text-editor-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.toolbar {
  display: flex;
  gap: 5px;
  margin-bottom: 5px;
}

.toolbar button {
  padding: 5px 10px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  outline: none;
}

.content {
  min-height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  overflow-y: auto;
}

.content:focus {
  border-color: dodgerblue;
  outline: none;
}

.ql-toolbar{
  border-radius: 8px 8px 0 0 !important;
  border-color: #dbdfe9 !important;
}

.ql-editor{
  font-family: 'Poppins' !important;
  height: 250px !important;
  font-size: 15px !important;
  border-color: #dbdfe9 !important;
}

.ql-container{
  border-radius: 0 0 8px 8px !important;
  border-color: #dbdfe9 !important;
}

.ql-header{
  font-family: 'Poppins' !important;
}